<template>
  <app-table :loading="loading" :headings="headers" :items="items">
    <template v-slot:actions="{ item }">
      <v-btn
        color="primary"
        small
        class="text-none"
        @click.stop="navigateRecord(item)"
      >
        View
      </v-btn>
    </template>
    <template v-slot:total="{ item }">
      <span>
        {{ item.total || 0 | secToHuman(" hours", " minutes") }}
      </span>
    </template>
    <template v-slot:start="{ item }">
      <span>{{ item.start | format("LL") }}</span>
      <chip-pto
        x-small
        class="xmt-[5px] xw-fit"
        v-if="item.is_pto"
        :approved="item.status == 'approved'"
      ></chip-pto>
      <chip-at
        x-small
        class="xmt-[5px] xw-fit"
        v-if="item.is_added"
        :approved="item.status == 'approved'"
      ></chip-at>
    </template>
  </app-table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    loading: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        {
          text: "Date",
          value: "start",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Time Spent",
          value: "total",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          tdClass: "xtext-right",
        },
      ],
    };
  },
  methods: {
    navigateRecord(item) {
      this.$router.push({
        name: "app-team-record-daily",
        params: { date: moment(item.start).format("YYYY-MM-DD") },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-clocking {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 10px;
  border-radius: 3px;
  &.status-in {
    color: #13d40f;
    background: rgba(106, 231, 62, 0.14);
  }
  &.status-brb {
    color: #4992ff;
    background: rgba(73, 146, 255, 0.14);
  }
  &.status-out {
    color: #ff4949;
    background: rgba(255, 73, 73, 0.14);
  }
}
</style>
