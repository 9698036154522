<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card flat class="xmin-h-[70vh] xpb-[60px]">
      <v-card-title class="xflex xflex-row xjustify-between xitems-center">
        <Filters
          :search="search"
          :value="selectedRange"
          @change="navigate"
          type="custom"
          tab="time"
          has-project
        >
        </Filters>
      </v-card-title>
      <v-card-text>
        <ProgressTable
          :loading="loading"
          :items="item ? item.summaries : []"
        ></ProgressTable>
        <!-- <pre>{{ item }}</pre> -->
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import moment from "moment";
import Filters from "../components/Filters.vue";
import ProgressTable from "../components/ProgressTable.vue";
export default {
  data() {
    return {
      loading: false,
      item: null,
      search: null,
      selectedRange: [
        moment().subtract(1, "day").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  computed: {
    selectedProject() {
      return this.$route.params.pid;
    },
    selectedUser() {
      return this.$route.params.user_id;
    },
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().subtract(1, "day").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ];
        val && this.fetch(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-user-time-custom",
          params: {
            start: val[0],
            end: val[1], 
            user_id: this.selectedUser,
            pid: this.selectedProject,
          },
        })
        .catch(() => {});
    },
    fetch(range) {
      if (!this.selectedProject || !this.selectedUser || !range) return;
      this.loading = true;
      this.$axios
        .get(
          `api/project-user-report/${this.selectedProject}/${this.selectedUser}/custom/${range.start}/${range.end}`
        )
        .then(({ data }) => {
          this.item = data;
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, ProgressTable },
};
</script>

<style lang="scss" scoped></style>
